body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 38px;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 15px;
}

.text-center {
  text-align: center;
}

.small {
  font-size: 80%;
  font-weight: 400;
}

.muted {
  color: #8c8c8c;
}

.primary {
  color: #1890ff;
}

.footer {
  min-height: 30vh;
  padding: 3rem;
  background-image: linear-gradient(to right, #dadee1, #fefefe, #f1f0ee);
}

.header {
  position: fixed;
  margin-bottom: 1rem;
  width: 100%;
  z-index: 99;
  transition: all 0.3s linear;
}

.header-scroll {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-header {
  font-size: 1.15rem;
  color: #47465c;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-header:hover {
  color: #1890ff;
}

.btn-header-active {
  font-size: 1.15rem;
  color: #1890ff;
  cursor: pointer;
}

.btn-header-active:hover {
  color: #1890ff;
}

.btn-footer {
  color: #9e9e9e;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-footer:hover {
  color: #1890ff;
}

.about-img {
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 350px;
}

.clinic-img {
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 500px;
}

.team-img {
  object-fit: cover;
  width: 100%;
  height: 500px;
  filter: grayscale(100%);
  transition: all 0.2s linear;
}

.team-img:hover {
  filter: grayscale(0%);
}

.testimonial-img {
  object-fit: cover;
  width: 100%;
}

.pp-img {
  object-fit: contain;
  width: 100%;
}

.spine-img {
  object-fit: contain;
  height: 100%;
  max-height: 600px;
}

.spine-img-small {
  object-fit: contain;
  height: 100%;
  max-height: 500px;
}

.section {
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.section-inner {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.section-dark {
  background-image: linear-gradient(to right, #34333d, #414049, #4a4952);
  width: 100%;
  min-height: 500px;
  padding: 2rem;
}

.section-dark-thin {
  background-image: linear-gradient(to right, #34333d, #414049, #4a4952);
  width: 100%;
  min-height: 35px;
}

.page {
  margin-top: 11rem;
  margin-bottom: 7rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.pda-content {
  max-width: 90%;
  min-height: 300px;
}

.interactive-panel {
  background-image: linear-gradient(
    45deg,
    #fff 2%,
    rgba(255, 255, 255, 0.1) 60%,
    #e3eff8 85%,
    #47a67c 85%
  );
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}

@media (min-width: 2000px) {
  .container {
    max-width: 1800px;
  }
}

@media screen and (max-width: 1200px) {
  .mobile-hidden {
    visibility: hidden;
    display: none;
  }
}

.logo-img {
  object-fit: contain;
  width: 100%;
  max-width: 200px;
  height: auto;
}

.logo-img-small {
  object-fit: contain;
  width: 100%;
  max-width: 130px;
  height: auto;
}

.header-footer-list {
  list-style-type: none;
  padding-left: 0;
}

/* breakpoint: md */

@media screen and (max-width: 768px) {
  h1,
  .h1 {
    font-size: 1.5rem;
  }
  h2,
  .h2 {
    font-size: 1.25rem;
  }
  h3,
  .h3 {
    font-size: 1.15rem;
  }
  h4,
  .h4 {
    font-size: 1.1rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
  }
}

/* breakpoint sm */

@media screen and (max-width: 576px) {
  .mobile-hidden {
    visibility: hidden;
    display: none;
  }
}

/* Customize website's scrollbar like Mac OS */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
